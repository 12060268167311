import React, { Component } from 'react';
import Logo from '../../Assets/Images/Logo-Transparent.png'
import HeaderLinksData from '../../Model/Links/HeaderLinks';
import AuthLinksData from '../../Model/Links/AuthLinks';
// import LegalLinksData from '../../Model/Links/LegalLinks';

class Header extends Component {
  render() {
    let headerlinksdata = HeaderLinksData;
    let authlinksdata = AuthLinksData;
      return (
      <nav className="navbar fixed-top navbar-expand-lg bg-body-tertiary bg-dark border-bottom border-body xc-nav-header" data-bs-theme="dark">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={Logo} alt="Logo" width="25" height="25" className="d-inline-block align-text-top" />
            <span className="xc-brand-header">𝗫𝗖𝗢𝗟𝗢𝗡𝗦 ™</span>
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="offcanvas offcanvas-end text-bg-dark xc-header-canvas" tabIndex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
            <div className="offcanvas-header xc-nav-canvas-header">
              <h5 className="offcanvas-title" id="offcanvasDarkNavbarLabel">© XCOLON PRIVATE LIMITED</h5>
              <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                {
                  headerlinksdata.map((item, index) => {
                    return <li className="nav-item" key={index}><a className="nav-link xc-header-link" href={item.route}>{item.title}</a></li>
                  })
                }
                {/* <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle xc-header-menu-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Legal
                  </a>
                  <ul className="dropdown-menu xc-header-menu">
                    {
                      LegalLinksData.map((item, index) => {
                        return <li key={index}><a className="dropdown-item xc-header-menu-item" href={item.route}>{item.title}</a></li>
                      })
                    }
                  </ul>
                </li> */}
              </ul>
              <span className="navbar-text">
                {
                  authlinksdata.map((item, index) => {
                    return <a key={index} className="nav-link" href={item.route}>{item.title}</a>
                  })
                }
              </span>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Header;