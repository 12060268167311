import React, { Component } from 'react';
// import TechnologiesData from '../../../Model/Technology/Technologies';

class HomePageComponent extends Component {
    render() {
        return (
            <div>
                <br />
                <div className="card text-center">
                    <div className="card-body xc-card-body">
                        <h1 className="card-text">𝗪𝗵𝗲𝗿𝗲 𝗜𝗻𝗻𝗼𝘃𝗮𝘁𝗶𝗼𝗻 𝗠𝗲𝗲𝘁𝘀 𝗜𝗺𝗽𝗹𝗲𝗺𝗲𝗻𝘁𝗮𝘁𝗶𝗼𝗻: 𝗕𝟮𝗕 𝗮𝗻𝗱 𝗕𝟮𝗖 𝗦𝗼𝗳𝘁𝘄𝗮𝗿𝗲 𝗦𝗼𝗹𝘂𝘁𝗶𝗼𝗻𝘀</h1>
                        <br />
                        <h4>ʙ2ʙ & ʙ2ᴄ ɪᴛ ꜱᴇʀᴠɪᴄᴇꜱ</h4>
                        <h4>ꜱᴏꜰᴛᴡᴀʀᴇ ᴛᴇᴄʜɴᴏʟᴏɢʏ ᴅᴇᴠᴇʟᴏᴘᴍᴇɴᴛ</h4>
                        <br />
                        {/* <a href="technology" className="btn btn-success xc-btn">Learn More &nbsp; <i className="fa fa-arrow-right"></i></a> */}
                        {/* <br /> */}
                    </div>
                </div>
                {/* <h3 className="text-center">Our Technologies & Services</h3>
                <br />
                {
                    TechnologiesData.map((item, index) => {
                        return (
                            <div key={index}>
                                <div className="card text-center mx-auto p-2 w-75 border border-success xc-home-tech-section">
                                    <div className="card-header"><strong>{item.type}</strong></div>
                                    <div className="card-body">
                                        <h5 className="card-title">{item.title}</h5>
                                        <p className="card-text">{item.description}</p>
                                        <a href={item.url} className="btn btn-success xc-btn" target="_blank" rel="noopener noreferrer">Connect &nbsp; <i className="fa fa-wifi"></i></a>
                                    </div>
                                    <div className="card-footer text-body-secondary"><strong>{item.status}</strong></div>
                                </div>
                                <br/>
                            </div>
                        )
                    })
                }
                <div id="xccarousel" className="carousel slide mb-6 xc-card-body" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                    <button type="button" data-bs-target="#xccarousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#xccarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#xccarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <svg className="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="rgb(0, 57, 9)"/></svg>
                            <div className="container">
                                <div className="carousel-caption">
                                    <h5><strong>Innovative Solutions for Business Success</strong></h5>
                                    <a href="technology" className="btn btn-success xc-btn">Learn More &nbsp; <i className="fa fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <svg className="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="rgb(0, 57, 9)"/></svg>
                            <div className="container">
                                <div className="carousel-caption">
                                    <h5><strong>Leading with Technology and Expertise</strong></h5>
                                    <a href="technology" className="btn btn-success xc-btn">Learn More &nbsp; <i className="fa fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <svg className="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="rgb(0, 57, 9)"/></svg>
                            <div className="container">
                                <div className="carousel-caption">
                                    <h5><strong>Revolutionizing Business with Expert Technology</strong></h5>
                                    <a href="technology" className="btn btn-success xc-btn">Learn More &nbsp; <i className="fa fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#xccarousel" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#xccarousel" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                <br /> */}
                <div className="card text-center mx-auto p-2 w-75 border border-success xc-home-tech-section">
                    <div className="card-header"><strong>Under Development</strong></div>
                    <div className="card-body">
                        <h5 className="card-title">𝗫𝗖𝗢𝗟𝗢𝗡𝗦 ™</h5>
                        <p className="card-text">Technology & web portal 𝗨𝗻𝗱𝗲𝗿 𝗗𝗲𝘃𝗲𝗹𝗼𝗽𝗺𝗲𝗻𝘁. Stay with us for updates.</p>
                    </div>
                </div>
            </div>
        )
    }
}
export default HomePageComponent